import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const graph = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Graph Programs"
          description="Programs of Graph under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>
        <div className="programming-project">
          <h1>Graph</h1>
          <section className="noSelect">
            <Link to="/dsa/">
              <button type="button" className="back-btn noSelect">
                <span>{"<"}= More Programs</span>
              </button>
            </Link>
          </section>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 7</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Graph Representation Using Adjacency Matrix
                  </h3>
                </div>
                <p className="basic-op">
                  •Create an array, size equal to No. of vertices
                  <br />
                  •Add edge
                  <br />
                  •Remove edge
                  <br />
                  •Print the graph
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Graph%20Representation%20Using%20Adjacency%20Matrix.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Graph Representation Using Adjacency List
                  </h3>
                </div>
                <p className="basic-op">
                  •Create an dynamic array, size equal to No. of vertices
                  <br />
                  •Add edge
                  <br />
                  •Remove edge
                  <br />
                  •Print the graph
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Graph%20Representation%20Using%20Adjacency%20List.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Breadth First Traversal</h3>
                </div>
                <p className="basic-op">
                  •Collections module is used
                  <br />
                  •Keep record of visited node
                  <br />
                  •proceeds level by level
                  <br />
                  •Check is it following BFT
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Breadth%20First%20Traversal.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Depth First Traversal</h3>
                </div>
                <p className="basic-op">
                  •Follows first a path form the starting to the ending node,
                  keep doing this
                  <br />
                  •Keep record of visited node
                  <br />
                  •Check is it following DFT
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Depth%20First%20Traversal.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Prim's Algorithm</h3>
                </div>
                <p className="basic-op">
                  •A greedy algorithm
                  <br />
                  •Start from one vertex and keep adding edges
                  <br />
                  •Until we get minimum spanning tree
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Prim's%20Algorithm.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Kruskal's algorithm</h3>
                </div>
                <p className="basic-op">
                  •A greedy algorithm
                  <br />
                  •Start from the edges with the lowest weight and keep adding
                  edges
                  <br />
                  •Edge create cycle, reject it
                  <br />
                  •Until we get the goal
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Kruskal's%20algorithm.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Dijkstra's Algorithm</h3>
                </div>
                <p className="basic-op">
                  •To find the shortest path between any two vertices of a graph
                  <br />
                  •Uses a greedy approach
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Graph/Dijkstra's%20Algorithm.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default graph
